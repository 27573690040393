<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <b-row class="d-flex justify-content-between align-items-center mx-1">
        <h2 class="grow">Proveedores asociados</h2>

        <!-- New customer -->

        <div class="d-flex align-items-center justify-content-end">
          <b-button
            class="add-button-rounded"
            variant="success"
            :to="{
              name: 'auth-register-establishment-supplier',
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="PlusIcon" size="18" />
          </b-button>
        </div>
      </b-row>

      <!-- Description -->
      <b-row class="mt-1 mb-1 providers-table">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <small>
            Aquí podrás ver y agregar a todos tus proveedores. Además, podrás
            ver qué productos y a qué tienda surten
          </small>
        </b-col>
      </b-row>

      <b-row class="mt-1 mb-1 description">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <small> Toca el nombre del proveedor para ver detalles </small>
        </b-col>
      </b-row>

      <!-- Searchbar -->
      <b-row clas="mt-3">
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="userName"
              placeholder="Buscar nombre"
              class="search-user"
            />
            <b-input-group-append v-if="userName">
              <b-button
                variant="outline-warning"
                @click="clearSearchbarAndResetSearch()"
              >
                Borrar
              </b-button>
            </b-input-group-append>
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <providers-list :users="users" :openModal="openModal" />

    <user-provider-modal
      :selectedUser="selectedUser"
      :handleRemoveSupplierProduct="handleRemoveSupplierProduct"
    />

    <establishment-suppliers-table
      :handleRemoveSupplierProduct="handleRemoveSupplierProduct"
    />

    <div class="mx-2 mb-2">
      <pagination
        :loading="loading"
        :handlePagination="handlePagination"
        :pagination="usersPagination"
        :entriesPerPage.sync="entriesPerPage"
      />
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import vSelect from "vue-select"
import { required } from "@validations"
import Ripple from "vue-ripple-directive"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { avatarText } from "@core/utils/filter"
import _ from "underscore"

import UserProviderModal from "./UserProviderModal.vue"
import ProvidersList from "./ProvidersList.vue"
import EstablishmentSuppliersTable from "./EstablishmentSuppliersTable2.vue"
import Pagination from "@/@core/components/Pagination.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    ValidationProvider,
    vSelect,
    ValidationObserver,
    avatarText,

    ProvidersList,
    UserProviderModal,
    EstablishmentSuppliersTable,
    Pagination,

    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  setup() {
    return {
      // Filter
      avatarText,
    }
  },
  data() {
    return {
      userName: null,
      name: null,
      required,
      loading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      entriesPerPage: "10",
      selectedUser: null,
      tableColumns: [
        {
          key: "name",
          label: "Proveedor",
        },
        {
          key: "cel_number",
          label: "Contacto",
        },
        {
          key: "products",
          label: "Productos asignados",
        },
      ],

      tableColumnsForStoreProducts: [
        {
          key: "product_name",
          label: "Nombre de producto",
        },
        {
          key: "store_name",
          label: "Sucursal",
        },
      ],
      perPage: ["10", "20", "50", "100"],
      perPageForCustomerOrders: ["10", "20", "50", "100"],
    }
  },
  computed: {
    ...mapGetters("users", ["users", "usersPagination"]),
  },
  watch: {
    userName: _.debounce(function () {
      this.loading = true
      this.fetchUsers({
        by_name: this.userName,
        by_resource_id: this.$route.params.id,
        by_active_status: true,
        by_role: "establishment_supplier",
        meta: {
          pagination: {
            page: this.usersPagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(() => {
          this.loading = false
          this.filterScopedStoreProducts(this.users)
        })
        .catch((error) => {
          this.loading = false
          const msg = error.response.data.messages
          this.$swal({
            title: "ERROR!",
            text: `${msg}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    }, 500),

    entriesPerPage() {
      this.loading = true
      this.loading = true
      this.fetchUsers({
        by_name: this.userName,
        by_resource_id: this.$route.params.id,
        by_active_status: true,
        by_role: "establishment_supplier",
        meta: {
          pagination: {
            page: this.usersPagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(() => {
          this.loading = false
          this.filterScopedStoreProducts(this.users)
        })
        .catch((error) => {
          this.loading = false
          const msg = error.response.data.messages
          this.$swal({
            title: "ERROR!",
            text: `${msg}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
  },

  beforeMount() {
    this.fetchUsers({
      by_resource_id: this.$route.params.id,
      by_active_status: true,
      by_role: "establishment_supplier",
    }).then(() => {
      this.loading = false
      this.filterScopedStoreProducts(this.users)
    })
  },

  methods: {
    ...mapMutations("users", ["filterScopedStoreProducts"]),
    ...mapActions("users", [
      "fetchUsers",
      "removeEstablishmentSupplier",
      "updateSupplierStoreProducts",
    ]),
    clearSearchbarAndResetSearch() {
      this.userName = ""
    },

    handleRemoveSupplierProduct(role, supplier) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "No podrás revertir esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.value) return

        this.updateSupplierStoreProducts({
          params: {
            action: "remove",
            supplier_id: supplier.id,
            remove_products_attributes: [
              {
                role_id: role.id,
              },
            ],
          },
        })
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Producto eliminado",
                icon: "EditIcon",
                variant: "success",
              },
            })
            this.fetchUsers({
              by_resource_id: this.$route.params.id,
              by_active_status: true,
              by_role: "establishment_supplier",
            })
              .then(() => {
                this.loading = false
                this.filterScopedStoreProducts(this.users)
                this.selectedUser = this.users.find(
                  (user) => user.id === supplier.id
                )
              })
              .catch((error) => {
                this.loading = false
                const msg = error.response.data.messages

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Error",
                    icon: "EditIcon",
                    variant: "danger",
                  },
                })
              })
          })
          .catch((error) => {
            const msg = error.response.data.messages
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                text: msg[0],
                icon: "EditIcon",
                variant: "danger",
              },
            })
          })
      })
    },

    // handleDelete(id) {
    //   this.$swal({
    //     title: "¿Estás seguro?",
    //     text: "No podrás revertir esto",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Sí, eliminar",
    //     cancelButtonText: "Cancelar",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.removeEstablishmentSupplier({
    //         params: {
    //           establishment_id: this.$route.params.id,
    //           supplier_id: id,
    //         },
    //       })
    //         .then((res) => {
    //           this.$toast({
    //             component: ToastificationContent,
    //             position: "top-right",
    //             props: {
    //               title: "Proveedor eliminado",
    //               icon: "EditIcon",
    //               variant: "success",
    //             },
    //           });
    //           this.fetchUsers({
    //             by_resource_id: this.$route.params.id,
    //             by_active_status: true,
    //             by_role: "establishment_supplier",
    //           })
    //             .then(() => {
    //               this.loading = false;
    //               this.filterScopedStoreProducts(this.users);
    //             })
    //             .catch((error) => {
    //               this.loading = false;
    //               const msg = error.response.data.messages;

    //               this.$toast({
    //                 component: ToastificationContent,
    //                 position: "top-right",
    //                 props: {
    //                   title: "Error",
    //                   icon: "EditIcon",
    //                   variant: "danger",
    //                 },
    //               });
    //             });
    //         })
    //         .catch((error) => {
    //           const msg = error.response.data.messages;
    //           this.$toast({
    //             component: ToastificationContent,
    //             position: "top-right",
    //             props: {
    //               title: "Error",
    //               icon: "EditIcon",
    //               variant: "danger",
    //             },
    //           });
    //         });
    //     }
    //   });
    // },

    openModal(user) {
      this.selectedUser = user
      this.$bvModal.show("provider-user-modal")
    },
    handlePagination({ page, per_page }) {
      this.loading = true
      this.fetchUsers({
        by_resource_id: this.$route.params.id,
        by_active_status: true,
        by_role: "establishment_supplier",
        meta: {
          pagination: {
            page: page,
            per_page: per_page,
          },
        },
      })
        .then(() => {
          this.loading = false
          this.filterScopedStoreProducts(this.users)
        })
        .catch((error) => {
          this.loading = false
          const msg = error.response.data.messages

          this.$swal({
            title: "ERROR!",
            text: `${msg}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.add-button-rounded {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}

.providers-table {
  display: none;

  @media (min-width: 767.98px) {
    display: block;
  }
}

.description {
  display: block;

  @media (min-width: 767.98px) {
    display: none;
  }
}
</style>
